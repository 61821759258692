import * as React from 'react';
import { useSignUp } from '../hooks';
import { SignUpContext } from '../utils/SignUpProvider';
import { Sign } from 'crypto';
import SignUpForm from './SignUpForm';
import SignInForm from './SignInForm';


function SignUpPopup () {
    let { showSignUp, hide } = React.useContext(SignUpContext);
    const [ register , setRegister ] = React.useState(false);


    
    return <div className="login-container" style={{ display: showSignUp ? "flex" : "none", }}>
        <div className="login-content">
            <div className="close" onClick={ () => {
                hide()
            }
            }>&nbsp;</div>
            <div className='loginHeader'>
                <span className={`loginText ${!register && "current"}` } onClick={ () => {
                    setRegister(false)
                }}>Login</span> or <span className={`loginText ${register && "current"}` } onClick={ () => {
                    setRegister(true)
                }}>Signup</span> 
            </div>
            

            { register ? <SignUpForm /> : <SignInForm />}
        </div>
      
        

    </div>
}

export default SignUpPopup