// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getStorage, ref } from "firebase/storage";
import { getAuth } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: "kalyzee-homework.firebaseapp.com",
  projectId: "kalyzee-homework",
  storageBucket: "kalyzee-homework.appspot.com",
  messagingSenderId: "240149492180",
  appId: "1:240149492180:web:fb2314caa008f42bc2a641",
  measurementId: "G-MG5JSV8J2L"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const storage = getStorage(app);
const auth = getAuth(app);

export { storage, analytics, auth };

export default app;