import {
  createBrowserRouter,
} from "react-router-dom";
import Home from "./pages/Home";
import History from "./pages/History";
import Solve from "./pages/Solve";
import Tutor from "./pages/Tutor";
import { MainTemplate } from "./templates/MainTemplate";

export default createBrowserRouter([
  {
    path: "/",
    element: <MainTemplate><Home /></MainTemplate>,
  },
  {
    path: "/history",
    element: <MainTemplate><History /></MainTemplate>,
  },
  {
    path: "/solve/:id?",
    element: <MainTemplate><Solve /></MainTemplate>,
  },
  {
    path: "/tutor/:id?",
    element: <MainTemplate><Tutor /></MainTemplate>,
  },      
]);