import { useForm } from "react-hook-form";
import loginSchema from "../utils/loginSchema";
import { yupResolver } from "@hookform/resolvers/yup";

interface AuthFormProps {
    error: string;
    submitLabel: string;
    onSubmit: (data: { email: string, password: string }) => void; 
}

export default function AuthForm(props: AuthFormProps) {
    
    const { register, handleSubmit, formState: { errors }, reset } = useForm({
        resolver: yupResolver(loginSchema),
      });
    
    return <form className="loginForm" onSubmit={handleSubmit(props.onSubmit)}>
        {props.error != "" && <div style={{background : "red", color: "white"}}>
            {props.error}
        </div> }    
        <div style={{marginBottom: 30, display: "flex", flexDirection: "column"}}>
            <label>Email</label>
            <input type="email"  {...register("email")} style={{height: 30, borderRadius: 30, border: 0}} />
            { errors.email && <p style={{color: "red", fontSize: 10, borderRadius: 30}}>{errors.email?.message}</p> }
        </div>
        <div style={{marginBottom: 30, display: "flex", flexDirection: "column"}}>
            <label>Password</label>
            <input type="password"  {...register("password")}   style={{height: 30, borderRadius: 30, border: 0}} />
            { errors.password && <p style={{color: "red",borderRadius: 30,  fontSize: 10}}>{errors.password?.message}</p> }
        </div>
        <button className="ask-button" type="submit">{props.submitLabel}</button>
        
    </form>
}