import { NavLink } from "react-router-dom";
import { useAppSelector } from "../hooks";
import { useContext } from "react";
import { SignUpContext } from "../utils/SignUpProvider";

export interface MenuButtonProps {
    title: string;
    href: string;
    requireUser ?: boolean;
    icon ?: string;
}

export default function MenuButton(props: MenuButtonProps) {
    const user = useAppSelector((state) => state.auth.user);
    const loginContext = useContext(SignUpContext);
    return (
        (user && props.requireUser) || !props.requireUser ? 
            <NavLink to={props.href} className={({ isActive, isPending }) =>
                isPending ? "menu-button pending" : isActive ? "menu-button active" : "menu-button"
            }>
                {props.icon ? <img src={props.icon} alt={props.title} /> : null}
                <div>{props.title}</div>
            </NavLink>
        :
        <a onClick={() => {
            loginContext.show();
        }} className="menu-button">
            {props.icon ? <img src={props.icon} alt={props.title} /> : null}
            <div>{props.title}</div>
        </a>
    );
}