import { AxiosResponse } from 'axios';
import api from '../common/api';
import { ref, uploadBytes } from 'firebase/storage';
import { auth, storage } from '../firebase';
import { v4 as uuidv4 } from 'uuid';
import { getAuth } from 'firebase/auth';

export interface CreateHomeworkRequest{
    message?: Message;
}

export interface CreateChatRequest{
    message?: Message;
}

enum ChatType{
    Homework = "homework",
    Tutor = "tutor"
}

interface ChatsResponse{
    chats: ChatResponse[];
}

export interface ChatResponse{
    id: string;
    messages: Message[];
}

export enum ROLE{
    system = "system",
    user = "user"

}

export interface Message{
    role: ROLE,
    content?: string;
    attachement?: string;
}

class HomeworkHelperClient{
    
    postHomework(homework: CreateHomeworkRequest) : Promise<AxiosResponse<ChatResponse, any>>{
        return api.post<ChatResponse>('/api/v2/chats', {
            ...homework.message, type: ChatType.Homework ,
            language: "fr"
        });
    }

    postChat(chat: CreateChatRequest) : Promise<AxiosResponse<ChatResponse, any>>{
        return api.post<ChatResponse>('/api/v2/chats', {
          ...chat.message, type: ChatType.Tutor,
          language: "fr"
        });
    }

    uploadDocument(file: File, chatID? :string): Promise<AxiosResponse<ChatResponse, any>>{
        const extension = file.name.slice(file.name.lastIndexOf("."))
        const storagePath = `homeworks/${auth.currentUser?.uid}/${uuidv4()}.${extension}`;
        const storageRef = ref(storage, storagePath);
        return new Promise((resolve, reject) => {
            uploadBytes(storageRef, file).then( (snapshot) => {
                this.postChat({
                    message: {
                        role: ROLE.user,
                        attachement: storagePath
                    }
                }).then( (response) => {
                    resolve(response);
                }).catch( (err) => {
                    reject(err);
                });
                return storagePath;
            }).catch( (err) => {
                reject(err);
            });
        });

    }

    postMessage(chatID: string, message: Message) : Promise<AxiosResponse<ChatResponse, any>>{
        return api.post<ChatResponse>(`/api/v2/chats/${chatID}`, {
            ...message
        });
    }
    
    fetchHistory(): Promise<AxiosResponse<ChatsResponse, any>>{
        return api.get<ChatsResponse>('/api/v2/chats');
    }

    getChat(chatID: string): Promise<AxiosResponse<ChatResponse, any>>{
        return api.get<ChatResponse>(`/api/v2/chats/${chatID}`);
    }

    delete(chatID: string){
        return api.delete(`/api/v2/chats/${chatID}`);
    }

}

const client = new HomeworkHelperClient();

export default client;