
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../utils/client";

const initialState : ChatState = {
    chats : [],
    messages: [],
};

interface ChatState {
    chats: any[];
    messages: any[];
}

export const fetchHistory = createAsyncThunk(
    'chats/fetchHistory',
    async (thunkAPI) => {
      const response = await api.fetchHistory()
      return response.data
    },
)

export const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
        
  },
  extraReducers: (builder) => {
        builder.addCase(fetchHistory.fulfilled, (state, action) => {
            state.chats = action.payload.chats;
        })
    }
});

// Action creators are generated for each case reducer function
export const {  } = chatSlice.actions;

export default chatSlice.reducer;

