import { getAuth } from "firebase/auth";
import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import app from "../firebase";
import { useAppSelector } from "../hooks";
import { SignUpContext } from "../utils/SignUpProvider";

export function Header() {

  const user = useAppSelector((state) => state.auth.user);
  const signUp = React.useContext(SignUpContext);

  return (
    <header className="header">
        <div className="container">
            <NavLink to={"/"} style={{height: "100%"}}><img src={require("../assets/logo.png")} className="logo" alt="logo" /></NavLink>
            <div className="header-menu" style={{display: "flex", alignItems: "center", justifyItems:"center"}}>
                <NavLink style={{display: "flex", alignItems: "center"}} to="/settings"><img src={require("../assets/menu/settings.png")} className="menu-icon" alt="menu" /></NavLink>
                <NavLink style={{display: "flex", alignItems: "center"}} to="/history"><img src={require("../assets/menu/history.png")} className="menu-icon" alt="menu" /></NavLink>
                
                <div style={{display: "flex", flexDirection: "row", alignItems: "center", cursor:'pointer', justifyItems: "center"}} onClick={ () => {
                    if (!user)
                        signUp.show();
                }}>
                    <img src={require("../assets/menu/account.png")} className="menu-icon" alt="menu" />
                    {user ? <>
                        <span className="user-name">{user.email}</span>
                        <button className="login-button" onClick={ () => {
                                getAuth(app).signOut();
                        }}>Logout</button>
                    </> : <button className="login-button">Login</button>}

                </div>
            </div>
        </div>
    </header>
  );
}  