
import './App.css';
import { RouterProvider } from 'react-router-dom';
import router from './router';
import app from './firebase';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { useAppDispatch, useAppSelector } from './hooks';
import { useEffect, useState } from 'react';
import { saveUser } from './store/authSlice';
import { SignUpProvider } from './utils/SignUpProvider';
import Loading from './components/Loading';




function App() {

  const auth = getAuth(app);
  const user = useAppSelector((state) => state.auth.user);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getAuth(app).authStateReady().then(() => {
      const user = getAuth(app).currentUser;
      if (user){
        saveUser({ email: user.email, uid: user.uid });
      }
      setLoading(false);
    });
  }, [dispatch]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        dispatch(saveUser({ email: user.email, uid: user.uid }));
      } else {
        dispatch(saveUser(undefined));
      }
    });
    return () => {
      unsubscribe();
    };
  }, [auth, dispatch]);
  
  return (<>{ loading ? <Loading /> : <SignUpProvider>
      <RouterProvider router={router} />
    </SignUpProvider>}</>);
}

export default App;
