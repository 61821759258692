import { getAuth } from "firebase/auth"
import app from "../firebase"
import { useAppSelector, useSignUp } from "../hooks"
import { SignUpContext } from "../utils/SignUpProvider"
import React from "react"
import client, { ROLE } from "../utils/client"
import { set } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import { SendDocument } from "../components/SendDocument"

export default function Home() {
    
    const isLogged = useAppSelector((state) => state.auth.user)
    const { show } = React.useContext(SignUpContext);
    const [question, setQuestion] = React.useState("")
    const navigate = useNavigate();

    return <div className="panel-information">
        <div style={{flex: 1, justifyItems: "center"}}>
            <h1 className="headline-desktop">Devoirs faciles</h1>
            <h2 className="headline-desktop HalisR-SRegular">Obtenez de l'aide pour vos devoirs 24/7 !</h2>
            <div style={{borderLeft: "10px solid #ffce6d", paddingLeft: 20, marginLeft: 20, fontSize: 12}}>
                <p>Accès aux réponses 24/7 pour vos questions de devoirs, quel que soit le moment où vous en avez besoin.</p>
                <p>Support dédié et personnalisé, avec des explications pour mieux comprendre chaque matière.</p>
                <p>Assistance immédiate et continue pour améliorer vos compétences académiques et votre autonomie.</p>
                <p>Plateforme facile d'utilisation, permettant de poser des questions et de recevoir des réponses claires et concises.</p>
            </div>
        </div>
        <div style={{flex: 1}}>
            <div className="content-helper" style={{background: "#f2f1ec", display: "flex", flexDirection:"column", alignItems:"center", borderRadius: 20, margin: 20}}>
                <h1 className="ask">Posez votre question</h1>
                <textarea value={question} onChange={(e)=> {setQuestion(e.target.value)}} className="asktextarea" placeholder="Entrez votre question ici" style={{width: "80%", height: 200, border: "none", borderRadius: 10}}/>
                <button style={{marginTop: 10}} className="ask-button" onClick={ () => {
                    if (!isLogged){
                        show()
                    }else {
                        if (question === "") return
                        client.postChat({   
                            message: {
                                role: ROLE.user,
                                content: question,
                            } 
                        }).then((rep) => {
                            navigate(`/tutor/${rep.data.id}`)
                            setQuestion("")
                        }).catch((err) => {});
                    }
                }}>Poser ma question (Gratuit)</button>
                <SendDocument styles={{marginTop: 10}} onNewDocument={ async (file) => {
                    const result = await client.uploadDocument(file);
                    navigate(`/tutor/${result.data.id}`)
                    
                }} />
            </div>
        </div>
        <h2 className="headline-mobile HalisR-SRegular">Obtenez de l'aide pour vos devoirs 24/7 !</h2>
        <h1 className="headline-mobile">Devoirs faciles</h1>
    </div>
}