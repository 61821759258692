import { useRef, useState } from "react";
import { SendDocument } from "./SendDocument";

export interface SendMessageBarProps {
    onMessage: (message: string) => Promise<boolean>;
    onNewDocument: (file: File) => void;
}


export default function SendMessageBar(props: SendMessageBarProps) {
    const file = useRef<HTMLInputElement>(null);
    const [message, setMessage] = useState("");

    return <div style={{display: "flex", flexDirection: "row", marginTop: 20, alignSelf: "stretch"}}>
    <div className="message-box">
        <textarea value={message} onChange={ (ev) => {
            setMessage(ev.target.value);
        }} />
        <button className="send-button" onClick={ async () => {
            if (message === "") {
                return;
            } 
            if (await props.onMessage(message)){
                setMessage("");
            }
        }}></button>
    </div>
    <SendDocument styles={{alignSelf: "flex-start"}} onNewDocument={props.onNewDocument} />
</div>
}