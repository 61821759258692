import { useDispatch } from "react-redux";
import { useAppDispatch, useAppSelector } from "../hooks";
import { useEffect } from "react";
import { fetchHistory } from "../store/chatSlices";
import { NavLink, useNavigate } from "react-router-dom";
import client, { ChatResponse } from "../utils/client";
import { SendDocument } from "../components/SendDocument";

export default function History() {
    const chats = useAppSelector((state) => state.chat.chats);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    
    const user = useAppSelector((state) => state.auth.user);
    useEffect(() => {
        if (!user){
            navigate("/");
        }
    }, [user]);

    useEffect(() => {
        dispatch(fetchHistory());
    }, [dispatch]); 

    return <div className="content-helper" style={{background: "#f2f1ec", display: "flex", flexDirection:"column", alignItems:"center", borderRadius: 20, margin: 20}}>
                <h1 className="ask">Historique de recherche</h1>
                <div className="history">
                    {chats.map((chat : ChatResponse) => {
                        return <NavLink to={`/tutor/${chat.id}`}><div className="history-item">
                                <span className="history-item-title">{(chat.messages[0] && chat.messages[0].content?.substring(0, 20)) ?? ""}</span>
                                <div className="history-item-close" onClick={ (ev) => {
                                    ev.preventDefault();
                                    client.delete(chat.id).then( () => {
                                        dispatch(fetchHistory());
                                    }) 
                                }}>x</div>
                        </div></NavLink>
                    })}
                </div>
                <div style={{display: "flex", flexDirection: "row", marginTop: 20}}>
                    <button className="ask-button" onClick={ () => {
                        navigate("/tutor")
                    }}>Poser ma question (Gratuit)</button>
                  <SendDocument onNewDocument={ async (file) => {
                        const document = await client.uploadDocument(file);
                        navigate(`/tutor/${document.data.id}`)

                    }} />
                </div>
            </div>
}