import { createUserWithEmailAndPassword, getAuth, signInWithCredential, signInWithEmailAndPassword } from "firebase/auth";
import app from "../firebase";
import React from "react";
import { SignUpContext } from "../utils/SignUpProvider";
import AuthForm from "./AuthForm";

export default function SignUpForm(){
    const signUp = React.useContext(SignUpContext);
    const [error, setError] = React.useState("");

    return <AuthForm error={error} submitLabel="Sign Up" onSubmit={ (data) => {
        createUserWithEmailAndPassword(getAuth(app), data.email, data.password)
        .then((userCredential) => {
          const user = userCredential.user;
          signUp.hide();
        })
        .catch((error) => {
            setError(error.message);
        });
    } } />
}