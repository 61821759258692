import React from "react";
import { useDispatch, useSelector } from 'react-redux'
import type { RootState, AppDispatch } from './store'

export const useAppDispatch = useDispatch.withTypes<AppDispatch>()
export const useAppSelector = useSelector.withTypes<RootState>()


export function useSignUp () {
  let [showSignUp, setShowSignUp] = React.useState(false);

  let show = () => {
    setShowSignUp(true);
  }
  let hide = () => {
    setShowSignUp(false);
  }

  return { showSignUp, show, hide };
};
