import { MathJaxContext } from "better-react-mathjax";
import { Message } from "../utils/client";

interface ChatContentProps {
    chatID: string;
    messages : Message[];
}

export default function ChatContent(props: ChatContentProps) {
    return <div style={{background: "white", height: 300, alignSelf: "stretch", overflowY: "scroll"}}>
    { props.messages.map((message) => { 
        return <div className="chat-content-message"> 
                <MathJaxContext>
                    {message.content}
                </MathJaxContext>
        </div>  
    })}
</div>
}