import { redirect, useNavigate, useParams } from "react-router-dom";
import ChatContent from "../components/ChatContent"
import SendMessageBar from "../components/SendMessageBar"
import React, { useEffect } from "react";
import client, { ROLE } from "../utils/client";
import { set } from "react-hook-form";
import { useAppSelector } from "../hooks";

export default function Solve() {
    const params = useParams<{id: string}>();
    const [messages, setMessages] = React.useState([] as any[]);
    const user = useAppSelector((state) => state.auth.user);
    
    const navigate = useNavigate();
    
    useEffect(() => {
        if (!user){
            navigate("/");
        }
    }, [user]);
    useEffect( () => {
        if (params.id) {  
            client.getChat(params.id).then( (response) => { 
                setMessages(response.data.messages);
            });
        }
    }, [params.id]);

    return <div className="content-helper" style={{background: "#f2f1ec", display: "flex", flexDirection:"column", alignItems:"center", borderRadius: 20, margin: 20}}>
                <h1 className="ask">Tuteur virtuel : posez votre question</h1>
                <ChatContent chatID="test" messages={messages} />
                <SendMessageBar onMessage={ async (message) => {
                    if (params.id){
                        await client.postMessage(
                            params.id!, {
                                content: message,
                                role: ROLE.user
                            }        
                        );
                        setMessages([...messages, {content: message}] as any[]);
                    }else {
                        const chat = await client.postChat({
                            message: {
                                content: message,
                                role: ROLE.user
                            }
                        })
                        navigate(`/solve/${chat.data.id}`)
                    }


                    return true;
                }} onNewDocument={ async (file) => {
                    const chatID = params.id;
                    const result = await client.uploadDocument(file, chatID);
                    if (!chatID){
                        navigate(`/tutor/${result.data.id}`)
                    }
                    

                }}/>
            </div>
}