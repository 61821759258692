import { useRef } from "react";

export interface SendDocumentProps{
    onNewDocument: (file: File) => void;
    styles? : React.CSSProperties
}

export function SendDocument(props: SendDocumentProps){
    const file = useRef<HTMLInputElement>(null);
    return <button className="send-document-button" style={props.styles} onClick={ () => {
        if (file.current) {
            file.current.click();
        }
    }}>
        <input id="file" ref={file} type="file" style={{display: "none"}} onChange={ () => {
            const newFile = file.current?.files![0];
            if (!newFile) {
                return;
            }
            props.onNewDocument(newFile);
        }} />      
        <img src={require("../assets/buttons/send-document.png")} alt="attach" style={{width: 20, height: 20, marginRight: 10}}/>
        Envoyer un document
    </button>
}