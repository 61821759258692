import axios, { AxiosRequestConfig, InternalAxiosRequestConfig } from "axios";
import { getAuth } from "firebase/auth";


if (process.env.NODE_ENV === 'development') {
    axios.defaults.baseURL =  "http://127.0.0.1:5001/kalyzee-homework/us-central1/app";
}else {
    axios.defaults.baseURL = "https://us-central1-kalyzee-homework.cloudfunctions.net/app";
}

axios.interceptors.request.use(
    async (config : InternalAxiosRequestConfig) => { 
        const user = await getAuth().currentUser?.getIdToken()
        if (user !== null){
            config.headers["Authorization"] = "Bearer " + user;
        }
        return config;
    }
  );

export default axios;