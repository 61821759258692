import React from "react";
import { useSignUp } from "../hooks";
import SignUpPopup from "../components/SignUpPopup";

let SignUpContext = React.createContext({ showSignUp: false, show: () => {}, hide: () => {} });
let { Provider } = SignUpContext;


type Props = {
    children?: React.ReactNode
};

let SignUpProvider: React.FC<Props> = ({ children } ) => {
  let { showSignUp, show, hide} = useSignUp();
  return (
    <Provider value={{ showSignUp, show, hide }}>
      <SignUpPopup />
      {children}
    </Provider>
  );
};

export { SignUpContext, SignUpProvider };