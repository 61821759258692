import BottomMenuBar from "../components/BottomMenuBar";
import { Header } from "../components/Header";
import MenuButton from "../components/MenuButton";
import SignUpForm from "../components/SignUpForm";

export function MainTemplate({ children }: { children: React.ReactNode }) {
    return (
        <div className="app">
            <Header />
            <div className="container" style={{paddingTop : 10}}>
                {children}
                <BottomMenuBar>
                    <MenuButton requireUser={true} icon={require("../assets/bottomBar/history.png")} title='Historique de recherche' href='/history'/>
                    <MenuButton requireUser={true} icon={require("../assets/bottomBar/ask.png")}  title='Résoudre un devoir' href='/solve'/>
                    <MenuButton requireUser={true} icon={require("../assets/bottomBar/history.png")} title='Préferences' href='/settings'/>
                </BottomMenuBar>
            </div>
      </div>
    );
}